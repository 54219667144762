.wishi-chat {
	--font: sans-serif;
	--background: #fff;
	--text-color: #222;
	--cta-bg-color: #fff;
	--cta-border-radius: 4px;
	--cta-main-color: #000;
	--question-container-max-width: 354px;
	--preference-wrapper-margin-bottom: 8px;
	--preference-wrapper-margin: 0 48px 12px 0;
	--preference-wrapper-margin-textarea-max-width: 100vh;
	--preference-wrapper-margin-textarea-width: 420px;
	--preference-additional-comments-title-display: block;
	--checkbox-container-margin: 0 auto;
	--checkbox-width: 18px;
	--checkbox-height: 18px;
	--checkbox-border: 1px solid #e4002b;
	--checkbox-checked-bg: #222;
	--checkbox-padding: 3px;
	--checked-icon-border: 1px solid #686868;
	--checked-icon-checked: #222;
	--quiz-message-selection-title-text-align: center;
	--quiz-message-title-font-size: 18px;
	--quiz-message-title-font-weight: bold;
	--quiz-message-title-line-height: 22px;
	--quiz-message-title-margin: 32px 35px 23px;
	--quiz-message-title-margin-mobile: 23px 35px 0px;
	--next-button-min-width: 0;
	--next-button-position: relative;
	--next-button-arrow-display: inline-block;
	--skip-button-font-size: 16px;
	--skip-button-width: auto;
	--quiz-buttons-align: flex-end;
	--quiz-buttons-margin: 60px 0px 60px;
	--select-box-display: contents;
	--select-box-text-container-margin: 0 auto;
	--sizes-max-width: initial;
	--sizes-margin: 0 auto 140px;
	--sizes-select-title-font-size: 14px;
	--sizes-select-title-margin: 0;
	--sizes-select-border-radius: 4px;
	--sizes-select-box-shadow: inset 0 0 0 1px #727272;
	--sizes-select-placeholder-color: #000000;
	--sizes-select-font-size: 16px;
	--sizes-select-dropdown-list-border: 1px solid #727272;
	--sizes-select-dropdown-list-border-top: 1px solid #727272;
	--sizes-select-dropdown-list-border-radius: 0 0 4px 4px;
	--sizes-select-dropdown-list-top: -3px;
	--sizes-shoes-size-width: 100%;
	--sizes-shoes-shoes-padding: initial;
	--sizes-shoes-size-display: inline-flex;
	--sizes-shoes-size-type-float: right;
	--sizes-shoes-size-type-padding: 3px 0 0 16px;
	--sizes-description-font-size: 14px;
	--quiz-back-button-src: url("data:image/svg+xml,%3Csvg width='28' height='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.96 4 9 13.934 19.96 24' stroke='%23000' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
	--quiz-back-button-width: 28px;
	--quiz-back-button-height: 28px;
	--textarea-border: solid 1px #aaaaaa;
	--textarea-text-color: #000;
	--input-textarea-border: 1px solid #727272;
	--input-textarea-height: 44px;
	--input-textarea-border-radius: 4px;
	--input-flag-dropdown-width: 80px;
	--input-container-instagram-width: initial;
	--input-container-margin: 0 auto;
	--input-tel-margin-bottom: 83px;
	--input-tel-padding-left: 90px;
	--input-icon-display: none;
	--input-label-display: initial;
	--item-hover: #1d1d1d;
	--item-border-color: #cccccc;
	--item-bg: #fff;
	--loader-color: #222;
	--quiz-message-width: fit-content;
	--quiz-message-narrow-width: 600px;
	--quiz-message-background: rgba(255, 255, 255, 0.1);
	--quiz-message-padding: 0 35px 0px;
	--quiz-message-padding-mobile: 20px 35px 60px;
	--next-button-height: 45px;
	--quiz-message-left: 0;
	--quiz-message-bottom: 68px;
	--tags-container-justify-content: flex-start;
	--tags-container-justify-content-mobile: flex-start;
	--tags-container-margin: 0;
	--tag-padding: 10px 10px;
	--tag-border: 1px solid #e6e6e6;
	--tag-border-radius: 2px;
	--tag-text-color: #222;
	--tag-selected-bg: #dddddd;
	--tag-selected-text-color: #222;
	--tag-border-selected: none;
	--select-all-color: #222;
	--select-all-border: 1px solid #222;
	--session-img-message-width: auto;
	--session-img-message-max-width: 277px;
	--session-img-message-max-height: 381px;
	--session-img-message-border-radius: 16px;
	--reply-options-container-justify-content: flex-start;
	--preferred-images-max-width: initial;
	--item-image-max-width: 380px;
	--text-input-border: 1px solid #cfcfcf;
	--text-input-border-radius: 4px;
	--text-input-form-padding: 10px;
	--text-input-send-weight: bold;
	--text-input-send-arrow-display-mobile: none;
	--text-input-send-text-display-mobile: flex;
	--sizes-dropdown-margin: 0 0 24px;
}

.wishi-chat .session-quiz {
	height: 90%;
}

.wishi-chat .session-quiz h5.title {
	font-weight: 900;
}

.wishi-chat .checkbox .checked {
	width: 8px;
	height: 8px;
}

.wishi-chat .quiz-message .back-btn {
	width: 160px;
	height: 45px;
	border-radius: 2px;
	border: solid 2px #000000;
	background-color: white;
	font-weight: 400;
}

.wishi-chat .quiz-message .next-btn {
	width: 100%;
	height: 45px;
	border-radius: 2px;
	border: solid 2px #000000;
	background-color: #000000;
	color: var(--white);
	font-weight: 400;
}

.wishi-chat .quiz-message .next-btn.disabled {
	background-color: #dddddd;
	border: none;
}

.wishi-chat .quiz-message .back-btn.disabled {
	opacity: 0.5;
}

.wishi-chat .quiz-message .next-btn::after {
	content: none;
}

.wishi-chat .quiz-message .back-btn::after {
	content: none;
}

.wishi-chat .quiz-message .next-btn::before {
	content: none;
}

.wishi-chat .quiz-message .back-btn::before {
	content: none;
}

.wishi-chat .quiz_progress-bar {
	bottom: 30px;
	left: 20px;
	right: 20px;
}

.wishi-chat .quiz-message.select-tags.brands .tags-container .tag-wrapper .tag-icon {
	display: none;
}

.wishi-chat .quiz-message.select-tags .tags-container .tag-wrapper .tag-icon img {
	border-radius: 0px;
}

.wishi-chat .quiz-message.select-tags .tags-container .tag-wrapper.selected .img-check {
	display: none;
}

.wishi-chat .quiz-message.select-tags .tags-container .tag-wrapper {
	margin-bottom: 10px;
	margin-right: 10px;
	height: 36px;
}

.wishi-chat .quiz-message.select-tags .tags-container .tag-wrapper .description {
	margin-top: 3px;
}

.wishi-chat .quiz-message.select-sizes .dropdown .description {
	display: none;
}

.wishi-chat .quiz-message.select-sizes .dropdown .title {
	position: initial;
}

.wishi-chat .quiz-message.select-sizes .dropdown .title .control .dropdown-arrow {
	border: none;
}

.wishi-chat .quiz-message.select-sizes .dropdown .select.open .title .control .dropdown-arrow {
	transform: rotate(180deg);
}

.wishi-chat .quiz-message.select-sizes .dropdown .title .control .dropdown-arrow.down {
	transform: none;
}

.wishi-chat .quiz-message.select-budget .dropdown .description {
	display: none;
}

.wishi-chat .quiz-message.select-budget .dropdown .title {
	position: initial;
}

.wishi-chat .quiz-message.select-budget .dropdown .title .control .dropdown-arrow {
	border: none;
}

.wishi-chat .quiz-message.select-budget .dropdown .select.open .title .control .dropdown-arrow {
	transform: rotate(180deg);
}

.wishi-chat .quiz-message.select-budget .dropdown .title .control .dropdown-arrow.down {
	transform: none;
}

.wishi-chat .quiz-message.select-square-checkbox .checkbox-container {
	flex-direction: column;
}

.wishi-chat .quiz-message.select-square-checkbox .checkbox-container .preference-wrapper {
	margin-bottom: 18px;
}

.wishi-chat .quiz-message.select-checkbox .preferences-text-container.wrap {
	height: auto;
}

.wishi-chat .session-quiz h5.title.age_range {
	visibility: hidden;
	position: relative;
}

.wishi-chat .session-quiz h5.title.age_range::before {
	visibility: visible;
	position: absolute;
	content: 'How old are you?';
}

/* width */
.wishi-chat *::-webkit-scrollbar {
	width: 4px;
}

/* Handle */
.wishi-chat *::-webkit-scrollbar-thumb {
	background: #d8d8d8;
}

/* Handle on hover */
.wishi-chat *::-webkit-scrollbar-thumb:hover {
	background: #d8d8d8;
}

/* feedback */
.wishi-chat.client-view .feedback-message .move-forward h1 {
	font-family: Moneta;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: 1.2px;
	color: #000000;
}

.wishi-chat.client-view .feedback-message .move-forward .feedback-note {
	display: none;
}

.wishi-chat.client-view .feedback-message .move-forward .chat-btn.link-btn.send-btn {
	font-size: 14px;
	text-transform: uppercase;
}

.wishi-chat .chat-btn.link-btn.send-btn {
	font-size: 16px;
	font-weight: 700;
}

.wishi-chat.client-view .feedback-message .move-forward {
	max-width: 800px;
	margin: 100px auto;
}

/* Email address */
.wishi-chat label.title.email {
	display: none;
}

.wishi-chat .quiz_progress-count {
	display: none;
}

.wishi-chat .quiz-message.select-sizes .dropdown .dropdown-list .option {
	padding: 15px 6px 6px 16px;
}

.wishi-chat .quiz-message.select-budget .dropdown .dropdown-list .option {
	padding: 15px 6px 6px 16px;
}

.wishi-chat .match-view h4.stylist-name {
	font-size: 20px;
}

@media screen and (min-width: 781px) {
	.wishi-chat .quiz-message {
		width: auto;
	}
}

.wishi-chat .quiz-message.select-checkbox {
	display: inherit;
	max-width: inherit;
}
