.wishi-chat {
	--text-input-border: 1px solid #cfcfcf;
	--text-input-border-radius: 4px;
	--text-input-form-padding: 10px;
	--text-input-send-weight: bold;
	--text-input-send-arrow-display-mobile: none;
	--text-input-send-text-display-mobile: flex;
	--chat-input-border: 1px solid #cfcfcf;
	--chat-input-padding: 10px;
}

.wishi-chat button.selected {
	background-color: var(--match-button-background);
}

.wishi-chat .question-button {
	font-size: var(--questionButtonFontSize);
	align-items: center;
}

.wishi-chat .question-tag {
	font-size: 12px !important;
}

.wishi-chat button.question-button.nextButton {
	background-color: var(--match-button-background) !important;
}

.wishi-chat *::-webkit-scrollbar {
	width: 4px;
}

.wishi-chat *::-webkit-scrollbar-thumb {
	background: #d8d8d8;
}

.wishi-chat *::-webkit-scrollbar-thumb:hover {
	background: #d8d8d8;
}

.wishi-chat .new-onboarding .btn-outline-light {
	color: inherit;
}
